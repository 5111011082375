import { useActiveOrganization } from "@/context/ActiveOrganizationProvider";
import { useSidebarContext } from "@/context/SidebarContextProvider";
import { useCurrentLocationFromHref } from "@/lib/path-utils";
import { capitalizedFileType, useFile } from "@/services/files.service";
import { InlineMenu } from "@components/SimpleTable/InlineMenu";
import { ContextMenuItem } from "@components/library/ContextMenu";
import { FileNameLabel } from "@components/library/Entities/FileNameLabel";
import { hlToast, ToastVariant } from "@components/library/Toast";
import { DirectoryActions, FileActions } from "@components/layouts/Sidebar/AppHeader/FileActions";
import { Breadcrumbs } from "@components/llama/PageTitleBreadcrumbs";
import { ReadmeButton } from "@components/llama/Projects/ReadmePanel";
import { Copy } from "@phosphor-icons/react";
import { SidebarToggleButton } from "../SidebarToggleButton";
import { FileTabs } from "./FileTabs";
import { HeaderFileName } from "./HeaderFileNamePopover";

export const AppHeader = ({ hideTabs = false, isLibrary = false }: { hideTabs?: boolean; isLibrary?: boolean }) => {
  const { sidebarCollapsed, topBarActionsTray, toggleSidebarRef } = useSidebarContext();
  const currentLocation = useCurrentLocationFromHref();
  const { slug } = useActiveOrganization();
  return (
    <div className="relative flex h-48 shrink-0 items-center justify-between gap-16 bg-background-base-3 pl-[20px] pr-20 @container/topbar ">
      <div className="flex flex-1 items-center">
        {sidebarCollapsed && (
          <div className="pr-12">
            <SidebarToggleButton />
          </div>
        )}
        {!hideTabs ? <FileTabs /> : <div className="grow" />}
      </div>

      <div className="flex items-center">
        {/* Key'd to make sure it closes on file change. I think this is the better behaviour, although
        it sometimes was wanted - e.g. you're in a nested file and you click up the breadcrumbs... it was
        quite nice to see the remaining breadcrumbs. Oh well.*/}
        {isLibrary ? (
          <Breadcrumbs
            items={[
              {
                href: `/${slug}/library`,
                content: (
                  <FileNameLabel
                    interactive
                    className="[[data-state='open']_&]:bg-background-base-35"
                    size={16}
                    name="Library"
                    type="directory"
                  />
                ),
              },
            ]}
          />
        ) : (
          <HeaderFileName key={currentLocation?.id} />
        )}
      </div>

      <ActionsTray />

      {/* Round the corner of the page below the top nav */}
      <div className="absolute -bottom-10 left-0 h-10 w-10 bg-background-base-3">
        <div className="h-10 w-10 rounded-tl-lg bg-white"></div>
      </div>
    </div>
  );
};

const ActionsTray = () => {
  const currentLocation = useCurrentLocationFromHref();

  /* Keeping these in for reference on how the sidepanel works */
  /* <Button
    key="versions"
    onClick={() => {
      setSidePanelState((state) => (state !== "versions" ? "versions" : "closed"));
    }}
    active={sidePanelState === "versions"}
    IconLeft={Hash}
    styling="ghost"
    className="opacity-80"
    size={24}
  >
    Versions
  </Button>
  <Button
    key="settings"
    onClick={() => {
      setSidePanelState((state) => (state !== "settings" ? "settings" : "closed"));
    }}
    active={sidePanelState === "settings"}
    IconLeft={Gear}
    styling="ghost"
    className="opacity-80"
    size={24}
  >
    Settings
  </Button> */

  return currentLocation?.type === "file" ? (
    <FileActions fileId={currentLocation.id} />
  ) : currentLocation?.type === "directory" ? (
    <DirectoryActions directoryId={currentLocation.id} />
  ) : null;
};
