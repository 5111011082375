import Button from "@components/library/Button";
import { HoverPopover } from "@components/library/Entities/shared/HoverPopover";
import { Dispatch, SetStateAction, useState } from "react";
import { AttentionDot } from "./AttentionDot";

/* A pulsating dot that also opens up an educational tooltip on hover */
export const Tourtip = ({
  title,
  body,
  children,
  active,
  setDismissed,
}: {
  title: string;
  body: string;
  children: React.ReactNode;
  active: boolean;
  setDismissed: Dispatch<SetStateAction<boolean>>;
}) => {
  const [open, setOpen] = useState(active);

  const popover = (
    <div className="relative flex w-320 max-w-256 flex-col gap-16 rounded-lg bg-background-base-1 px-12 py-12 text-text-base-1 shadow-card-sm ">
      <div className="flex flex-col gap-16">
        <div className="flex flex-col gap-8">
          <div className="text-14-20 font-medium text-text-secondary-2">{title}</div>
          <div className="text-12-16 text-text-secondary-1">{body}</div>
        </div>

        <div>
          <Button elevated styling="outline" onClick={() => setDismissed(true)}>
            Got it
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <HoverPopover
      arrow
      open={open}
      onOpenChange={setOpen}
      openDelay={200}
      closeDelay={100}
      trigger={<AttentionDot>{children}</AttentionDot>}
      content={popover}
    />
  );
};
