import {
  DOCS_DATASETS,
  DOCS_EVALUATORS,
  DOCS_FLOWS,
  DOCS_MONITORING,
  DOCS_RUN_EVALUATIONS,
  DOCS_TOOLS,
  DOCS_API_TOOLS,
  DOCS_API_FLOWS,
} from "@/lib/constants";
import { File } from "@/types/app/file";
import { RadixAppDialog } from "@components/library/AppDialog";
import { InlineLink } from "@components/library/InlineLink";
import { ArrowUpRightIcon } from "@components/library/Icons";
import { CodeSnippetDialog } from "./CodeSnippet";
import { useFile } from "@/services/files.service";

interface UseFileModalProps {
  fileId?: string;
  file?: File;
  open: boolean;
  onClose: () => void;
}

export const UseFileModal = ({ fileId, file: fileFromProps, open, onClose }: UseFileModalProps) => {
  const { file: fetchedFile } = useFile(fileId ? { fileId } : undefined);
  const file = fileFromProps || fetchedFile;

  if (!file) return null;

  if (file.type === "prompt") {
    return <CodeSnippetDialog prompt={file} open={open} onClose={onClose} />;
  }

  if (file.type === "tool") {
    return (
      <RadixAppDialog
        className="!w-[620px]"
        open={open}
        onClose={onClose}
        title={`How to use this ${file.tool_type === "json_schema" ? "JSON Schema" : ""}
          ${file.tool_type === "google" ? "Google" : ""}
          ${file.tool_type === "pinecone_search" ? "Pinecone Search" : ""}
          ${file.tool_type === "snippet" ? "Snippet" : ""}
          ${file.tool_type === "get_api_call" ? "Get API Call" : ""}
           Tool`}
        description={
          <>
            {file.tool_type === "json_schema" && (
              <>This JSON Schema Tool versions controls the JSON Schema used for tool calling, and Logs each use.</>
            )}
            {file.tool_type === "google" && <>This Google Tool extends the capabilities of a Prompt with search.</>}
            {file.tool_type === "pinecone_search" && (
              <>This Pinecone Search tool extends the capabilities of a Prompt with search over a Pinecone database.</>
            )}
            {file.tool_type === "snippet" && (
              <>This Snippet tool can be used to insert common snippets of text into a Prompt.</>
            )}
            {file.tool_type === "get_api_call" && (
              <>This Get API Call tool can be used to call an API and record the versions and Logs of every use.</>
            )}
            <InlineLink IconRight={ArrowUpRightIcon} className="ml-4" target="_blank" href={DOCS_TOOLS}>
              View docs
            </InlineLink>
          </>
        }
      >
        <div className="flex flex-col gap-32 text-13-20 text-text-base-2">
          <section className="flex flex-col gap-8 ">
            {file.tool_type === "json_schema" && (
              <>
                <h2 className="text-14-20 font-medium text-text-base-1">Using Tool Calling</h2>

                <p>This tool can be used to extend the capabilities of your AI assistant by adding it to a prompt.</p>

                <ol className="ml-4 list-inside list-decimal ">
                  <li>Go to the Prompt you want to enhance</li>
                  <li>In the prompt editor, add a reference to this tool</li>
                </ol>

                <video muted autoPlay className="my-12" loop>
                  <source src="/assets/animations/how to use json schema.mp4" type="video/mp4" />
                </video>
              </>
            )}

            {file.tool_type === "google" ||
              file.tool_type === "pinecone_search" ||
              (file.tool_type === "snippet" && (
                <>
                  <h2 className="text-14-20 font-medium text-text-base-1">Using this Tool in a Prompt</h2>

                  <p>
                    This Tool can be used within your prompt template to call and insert the results into your prompt.
                  </p>

                  <ol className="ml-4 list-inside list-decimal ">
                    <li>Go to the Prompt you want to enhance</li>
                    <li>
                      In the prompt template, add a reference to this tool using &#123;&#123; tool_name &#125;&#125;{" "}
                    </li>
                  </ol>

                  <video muted autoPlay className="my-12" loop>
                    <source src="/assets/animations/how to use a prompt tool.mp4" type="video/mp4" />
                  </video>
                </>
              ))}
          </section>

          <section className="flex flex-col gap-8 ">
            <h2 className="text-14-20 font-medium text-text-base-1">Logging to the Tool</h2>

            <p>This tool is used to record Logs and versions of the non-AI parts of your system.</p>

            <p>
              For detailed integration instructions, refer to our
              <InlineLink className="ml-4" IconRight={ArrowUpRightIcon} target="_blank" href={DOCS_API_TOOLS}>
                API docs on Tools
              </InlineLink>
            </p>
          </section>
        </div>
      </RadixAppDialog>
    );
  }

  if (file.type === "flow") {
    return (
      <RadixAppDialog
        className="!w-[620px]"
        open={open}
        onClose={onClose}
        title="How to use this Flow"
        description={
          <>
            Flows are orchestrations of Prompts, Tools and other code, enabling you to evaluate and improve your full AI
            pipeline. They are not runnable on Humanloop, but can capture the logs and versions of your pipeline.
            <InlineLink IconRight={ArrowUpRightIcon} className="ml-4" target="_blank" href={DOCS_FLOWS}>
              View docs
            </InlineLink>
          </>
        }
      >
        <div className="flex flex-col gap-32 text-13-20 text-text-base-2">
          <section className="flex flex-col gap-8">
            <h2 className="text-14-20 font-medium text-text-base-1">Logging to the Flow via API</h2>

            <p>
              Logs can be sent to a Flow by using the Flow ID. See{" "}
              <InlineLink className="ml-4" IconRight={ArrowUpRightIcon} target="_blank" href={DOCS_API_FLOWS}>
                API docs
              </InlineLink>{" "}
              for more details.
            </p>
          </section>
          <section className="flex flex-col gap-8">
            <h2 className="text-14-20 font-medium text-text-base-1">Evaluating this Flow</h2>

            <p>
              This Flow can be evaluated via the API. For detailed integration instructions, refer to our
              <InlineLink className="ml-4" IconRight={ArrowUpRightIcon} target="_blank" href={DOCS_RUN_EVALUATIONS}>
                guide on running external evaluations
              </InlineLink>
              .
            </p>
          </section>
        </div>
      </RadixAppDialog>
    );
  }

  if (file.type === "evaluator") {
    return (
      <RadixAppDialog
        className="!w-[620px]"
        open={open}
        onClose={onClose}
        title="How to use this Evaluator"
        description={
          <>
            The evaluator can be used in evaluations or for monitoring incoming logs.
            <InlineLink IconRight={ArrowUpRightIcon} className="ml-4" target="_blank" href={DOCS_EVALUATORS}>
              View docs
            </InlineLink>
          </>
        }
      >
        <div className="flex flex-col gap-32 text-13-20 text-text-base-2">
          <section className="flex flex-col gap-8 ">
            <h2 className="text-14-20 font-medium text-text-base-1">Using the Evaluator for Monitoring</h2>

            <p>
              This evaluator can be applied to all incoming logs by connecting it up for monitoring. The results and
              controls will be visible in the Logs.
            </p>

            <ol className="ml-4 list-inside list-decimal ">
              <li>Go to the Monitoring section</li>
              <li>Select &quot;Add Evaluator&quot;</li>
              <li>Choose this Evaluator from the list</li>
            </ol>

            <p>
              For more details on setting up monitoring, read the
              <InlineLink className="ml-4" IconRight={ArrowUpRightIcon} target="_blank" href={DOCS_MONITORING}>
                docs on monitoring
              </InlineLink>
            </p>

            <video muted autoPlay className="my-12" loop>
              <source src="/assets/animations/how to use monitoring.mp4" type="video/mp4" />
            </video>
          </section>

          <section className="flex flex-col gap-8 ">
            <h2 className="text-14-20 font-medium text-text-base-1">Using the Evaluator in an Evaluation</h2>

            <ol className="ml-4 list-inside list-decimal ">
              <li>Go to the Prompt or Tool you want to evaluate</li>
              <li>Go to the Evaluation page and create a new evaluation</li>
              <li>Select this Evaluator</li>
            </ol>

            <p>
              To run an evaluator in code, read the
              <InlineLink className="ml-4" IconRight={ArrowUpRightIcon} target="_blank" href={DOCS_RUN_EVALUATIONS}>
                docs on running external evaluations
              </InlineLink>
            </p>
          </section>
        </div>
      </RadixAppDialog>
    );
  }

  if (file.type === "dataset") {
    return (
      <RadixAppDialog
        className="!w-[620px]"
        open={open}
        onClose={onClose}
        title="How to use this Dataset"
        description={
          <>
            The dataset can be used for the inputs and targets of an evaluation. It can also be used to fine-tune a
            model.
            <InlineLink IconRight={ArrowUpRightIcon} className="ml-4" target="_blank" href={DOCS_DATASETS}>
              View docs
            </InlineLink>
          </>
        }
      >
        <div className="flex flex-col gap-32 text-13-20 text-text-base-2">
          <section className="flex flex-col gap-8">
            <h2 className="text-14-20 font-medium text-text-base-1">Using the Dataset for Evaluation </h2>

            <ol className="ml-4 list-inside list-decimal ">
              <li>Go to the Prompt or Tool you want to evaluate</li>
              <li>Go to the Evaluation page and create a new evaluation</li>
              <li>Select this Dataset</li>
            </ol>

            <p>
              To run evaluations in code, read the
              <InlineLink className="ml-4" IconRight={ArrowUpRightIcon} target="_blank" href={DOCS_RUN_EVALUATIONS}>
                docs on running external evaluations
              </InlineLink>
            </p>

            <video muted autoPlay className="my-12" loop>
              <source src="/assets/animations/dataset-eval.mp4" type="video/mp4" />
            </video>
          </section>
        </div>
      </RadixAppDialog>
    );
  }

  return null;
};
