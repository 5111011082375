// TabGroup is a connected array of buttons
// It shows that they're connected as you hover over one, and
// TODO: it should collapse to a dropdown responsively.
// Note: this doesn't handle the content/panel part at all. It's just the buttons.
//
// https://www.figma.com/design/kztHoQhvuBLH8WMxfDxZpn/Layout?node-id=12-5162&t=PNLDG1DgD9PK7u3M-11
import { classNames, ConditionalWrapper } from "@/lib/utils";
import React from "react";
import { UnstyledButtonBase } from "../Button";
import { ConditionalTooltip, TooltipProps } from "../Tooltip";

interface TabGroupProps<T extends string> {
  selectedKey: T | undefined;
  tabs: {
    key: T;
    title: string;
    Icon?: (props: React.ComponentProps<"svg">) => JSX.Element;
    disabled?: boolean;
    tooltip?: string;
    tooltipProps?: Omit<TooltipProps, "children" | "content">;
    attentionDot?: boolean;
    onSelect?: (key: T) => void;
    wrapper?: (children: React.ReactNode) => React.ReactNode;
  }[];
}

const TabGroup = <T extends string>({ tabs, selectedKey }: TabGroupProps<T>) => {
  return (
    <div
      className={classNames(
        "group inline-flex gap-4 rounded-ms transition-colors duration-1000 hover:bg-background-base-325",
      )}
    >
      {tabs.map(({ key, title, Icon, disabled, wrapper, tooltip, tooltipProps, onSelect, ...buttonProps }, i) => (
        <ConditionalTooltip key={i} content={tooltip} condition={!!tooltip} {...tooltipProps}>
          <ConditionalWrapper condition={!!wrapper} wrapper={wrapper}>
            <UnstyledButtonBase
              key={key}
              disabled={disabled}
              // Disabled tabs are not focusable
              tabIndex={disabled ? -1 : 0}
              className={classNames(
                "relative -my-1 flex items-center gap-6 rounded-ms border border-transparent px-8 py-6 font-normal",
                disabled
                  ? "text-text-base-4"
                  : selectedKey === key
                    ? "border border-stroke-base-3 bg-background-base-35 font-medium !text-text-base-1"
                    : "text-text-base-2 hover:bg-background-base-35 hover:text-text-base-1 focus:bg-background-base-325 active:bg-background-base-35",
                "focus:outline-none",
                "select-none",
              )}
              {...buttonProps}
              onClick={() => onSelect?.(key)}
            >
              <span className="flex w-full items-center gap-6 text-12-12">{title}</span>
            </UnstyledButtonBase>
          </ConditionalWrapper>
        </ConditionalTooltip>
      ))}
    </div>
  );
};

export default TabGroup;
