import { useCallback, useLayoutEffect, useRef, useState } from "react";

/**
 * Hook to get the dimensions of an element using ResizeObserver
 */
const useElementDimensions = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [dimensions, setDimensions] = useState<DOMRect | undefined>(undefined);

  const refresh = useCallback(() => {
    const domRect = ref.current?.getBoundingClientRect();
    if (domRect) {
      setDimensions(domRect);
    }
  }, []);

  useLayoutEffect(() => {
    const element = ref.current;
    if (!element) return;

    // Create a ResizeObserver instance and observe the element
    const resizeObserver = new ResizeObserver(() => {
      refresh();
    });

    resizeObserver.observe(element);

    // Cleanup the observer on component unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, [refresh]);

  return { dimensions, ref };
};

export default useElementDimensions;
