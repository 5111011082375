/** This is a panel that slides out from the right side of the screen. */
import { useHasScrolled } from "@/lib/useHasScrolled";
import IconButton from "@components/library/IconButton";
import { X } from "@phosphor-icons/react";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect } from "react";

export const SIDE_PANEL_WIDTH = 382;

interface SidePanelProps {
  className?: string;
  width?: number;
  open: boolean;
  handleClose: () => void;
  title?: React.ReactNode;
  content: React.ReactNode;
}

export const SidePanel = ({
  width = SIDE_PANEL_WIDTH,
  className,
  title,
  content,
  open,
  handleClose,
}: SidePanelProps) => {
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
        console.log("dispatched resize");
      }, 5000);
    }
  }, [open]);
  const [contentRef, hasScrolled, resetScroll] = useHasScrolled<HTMLDivElement>();

  return (
    <AnimatePresence mode="sync">
      {open && (
        <motion.div
          initial={{ width: 0 }}
          animate={{ width: `${width}px` }}
          exit={{ width: 0 }}
          transition={{
            type: "tween",
            duration: 0.2,
            ease: "easeInOut",
          }}
          className="ml-8 mr-8 h-full shrink-0 overflow-hidden rounded-lg"
        >
          {/* Fixed width here so content doesn't temporarily wrap as it grows */}
          <div style={{ width: `${width}px` }} className="mb-80 flex h-full flex-col overflow-auto bg-white ">
            {/* 48px if you include the subtle border */}
            <div className="flex h-[47px] shrink-0 items-center justify-between px-20">
              <div className="font-semibold text-12-12 text-text-base-3">{title}</div>
              <IconButton Icon={X} shade="gray" styling="ghost" size={24} onClick={handleClose} />
            </div>
            {hasScrolled ? <div className="h-1 animate-fade-in bg-stroke-base-2" /> : <div className="h-1 " />}

            <div ref={contentRef} className="grow overflow-auto px-20 pb-40">
              {content}
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
