import { useActiveOrganization } from "@/context/ActiveOrganizationProvider";
import { useSidebarContext } from "@/context/SidebarContextProvider";
import useConfirm from "@/context/useConfirm";
import { FILETYPE_TO_DOCS_API_URL, ROOT_DIRECTORY_NAME } from "@/lib/constants";
import { getDirectoryHref, useCurrentLocationFromHref } from "@/lib/path-utils";
import {
  Directory,
  DirectoryFile,
  directoryFileFromFile,
  updateDirectory,
  useDirectoryStructure,
} from "@/services/directories.service";
import { capitalizedFileType, renameFile, shouldWarnOnPathChange } from "@/services/files.service";
import Button, { UnstyledButtonBase } from "@components/library/Button";
import { FileNameLabel } from "@components/library/Entities/FileNameLabel";
import { HoverPopover } from "@components/library/Entities/shared/HoverPopover";
import { ArrowUpRightIcon } from "@components/library/Icons";
import IdDisplay from "@components/library/IdDisplay";
import { InlineLink } from "@components/library/InlineLink";
import { hlToast, ToastVariant } from "@components/library/Toast";
import { classNames } from "@components/library/utils/classNames";
import { UseFileModal } from "@components/llama/Dashboard/UseFileModal";
import { FormikTextInput } from "@components/molecules/FormikInput";
import { BreadcrumbProps } from "@library/Breadcrumbs";
import { Form, Formik } from "formik";
import { isEmpty } from "lodash";
import Link from "next/link";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTimeout } from "usehooks-ts";
import * as Yup from "yup";

/** File name with popover and breadcrumbs */
export const HeaderFileName = () => {
  const { directoryStructure: structure } = useSidebarContext();
  const currentLocation = useCurrentLocationFromHref();

  const [open, setOpen] = useState(false);
  const [showHowToUse, setShowHowToUse] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { slug } = useActiveOrganization();

  if (!currentLocation) return null;
  if (currentLocation?.type === "directory" && currentLocation.id === "home")
    return (
      <Link href={`/${slug}`}>
        <FileNameLabel
          interactive
          // This is to apply a class if the parent popover trigger is set to open.
          // The syntax works... but I don't know if its documented anywhere.
          //  Got there through trial and error + GPT.
          className="[[data-state='open']_&]:bg-background-base-35"
          size={16}
          name={ROOT_DIRECTORY_NAME}
          type="directory"
        />
      </Link>
    );

  const item =
    currentLocation.type === "file"
      ? structure?.files.find((file) => file.id === currentLocation.id)
      : structure?.directories.find((dir) => dir.id === currentLocation.id);

  const trigger = (
    <UnstyledButtonBase onClick={() => setOpen(true)} onDoubleClick={() => setIsEditing(true)}>
      <FileNameLabel
        interactive
        className="[[data-state='open']_&]:bg-background-base-35"
        size={16}
        name={item?.name}
        type={item?.type === "directory" ? "directory" : item?.type}
      />
    </UnstyledButtonBase>
  );

  const content = (
    <div className="flex w-320 max-w-sm flex-col gap-12 rounded-lg bg-background-base-1 p-12 text-text-base-1 shadow-card-sm">
      {item ? <EditableFileName isEditing={isEditing} setIsEditing={setIsEditing} fileOrDirectory={item} /> : null}
      <div className="flex items-center justify-between gap-4 text-12-16 text-text-base-3">
        <div className="ml-6 font-mono">{item?.id && <IdDisplay id={item?.id} full copyIcon />}</div>
        {item ? (
          <InlineLink target="_blank" IconRight={ArrowUpRightIcon} href={FILETYPE_TO_DOCS_API_URL[item.type]}>
            API docs
          </InlineLink>
        ) : null}
      </div>
      <BreadcrumbStack />
    </div>
  );

  return (
    <>
      <HoverPopover
        // Want to lock it open if we're editing, even if the confirm modals come up etc.
        open={open || isEditing}
        onOpenChange={setOpen}
        trigger={trigger}
        content={content}
        openDelay={100}
        closeDelay={300}
      />
      {item && item.type !== "directory" && (
        <UseFileModal fileId={item.id} open={showHowToUse} onClose={() => setShowHowToUse(false)} />
      )}
    </>
  );
};

const EditableFileName = ({
  fileOrDirectory,
  isEditing,
  setIsEditing,
}: {
  fileOrDirectory: DirectoryFile | Directory;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
}) => {
  // This is a prevent blur from reseting the form when we're trying to submit.
  // A "lock to say stay in editing mode".
  const [isTryingToSubmit, setIsTryingToSubmit] = useState(false);
  const { onChange: onDirectoryStructureChange } = useDirectoryStructure();
  const confirm = useConfirm();
  const inputRef = useRef<HTMLInputElement>(null);
  const fileType = fileOrDirectory.type === "directory" ? "Directory" : capitalizedFileType(fileOrDirectory.type);

  const onSubmit = async ({ name }: { name: string }, { setErrors }: { setErrors: any }) => {
    setIsTryingToSubmit(true);
    try {
      if (!fileOrDirectory?.id) {
        throw new Error(`${fileType} ID not found: ${fileOrDirectory?.id}`);
      }
      const shouldWarn =
        fileOrDirectory.type === "directory"
          ? shouldWarnOnPathChange({
              type: "directory",
              item: fileOrDirectory,
            })
          : shouldWarnOnPathChange({
              type: "file",
              item: fileOrDirectory,
            });

      if (shouldWarn) {
        await confirm({
          title: `Warning: renaming can break API calls`,
          content: shouldWarn.warningMessage,
          confirmationText: "Rename",
          cancellationText: "Cancel",
        });
      }

      if (fileOrDirectory.type === "directory") {
        await updateDirectory(fileOrDirectory.id, { name })
          .then((response) => {
            onDirectoryStructureChange([
              {
                type: "update",
                item: { type: "directory", item: response.data },
              },
            ]);
            setIsEditing(false);
          })
          .catch((error) => {
            if (error.response?.status === 409) {
              setErrors({ name: "Name is already taken" });
            }
          });
      } else {
        renameFile({
          file: fileOrDirectory,
          name,
          onSuccess: async (updatedFile) => {
            onDirectoryStructureChange([
              {
                type: "update",
                item: { type: "file", item: directoryFileFromFile(updatedFile) },
              },
            ]);
            setIsEditing(false);
          },
          onError: (error) => {
            if (error.response?.status === 409) {
              setErrors({ name: "Name is already taken" });
            }
          },
        });
      }
    } catch (e: any) {
      if (e !== undefined) {
        const detail = e?.response?.data?.detail;
        if (detail) {
          hlToast({ title: detail.msg, description: detail.description, variant: ToastVariant.Error });
        } else {
          throw e;
        }
      }
    } finally {
      setIsTryingToSubmit(false);
    }
  };
  // It autofocuses, immediately and we don't want that as it can be too easy to change the name.
  useTimeout(() => {
    if (!isEditing) {
      inputRef.current?.blur();
    } else {
      inputRef.current?.focus();
    }
  }, 10);

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    }
  }, [isEditing]);

  return (
    <Formik
      // Key'd to make sure this doesn't keep values if file changes.
      key={fileOrDirectory.id}
      initialValues={{
        name: fileOrDirectory?.name || "",
      }}
      validateOnBlur={false}
      onSubmit={onSubmit}
      validationSchema={Yup.object({
        name: Yup.string().max(128, "Make the name shorter").required("Required"),
      })}
    >
      {({ values, isValid, isSubmitting, setValues }) => {
        const reset = () => {
          setValues({ name: fileOrDirectory?.name || "" });
          setIsEditing(false);
          inputRef.current?.blur();
        };

        return (
          <Form className="flex w-full grow items-start gap-12">
            <FormikTextInput
              ref={inputRef}
              onClick={() => setIsEditing(true)}
              onKeyDown={(e) => {
                if (e.key === "Escape") {
                  e.preventDefault();
                  e.stopPropagation();
                  reset();
                }
              }}
              onBlur={() => {
                if (!isTryingToSubmit) {
                  reset();
                }
              }}
              data-1p-ignore
              boxSize={24}
              name="name"
              readOnly={!isEditing}
              placeholder={`${fileType} name`}
              containerClasses="flex-grow "
            />
          </Form>
        );
      }}
    </Formik>
  );
};

/** Stack of breadcrumbs, most recent first */
const BreadcrumbStack = () => {
  const { directoryStructure: structure } = useSidebarContext();
  const currentLocation = useCurrentLocationFromHref();
  const { slug } = useActiveOrganization();
  const size = 24;

  const directoryStack = useMemo(() => {
    if (!currentLocation || !structure) return [];

    const directories: Directory[] = [];
    let directory: Directory | undefined;

    // Find the starting directory based on current location
    if (currentLocation.type === "file") {
      const item = structure.files.find((file) => file.id === currentLocation.id);
      directory = structure.directories.find((dir) => dir.id === item?.directory_id);
    } else if (currentLocation.type === "directory") {
      directory = structure.directories.find((dir) => dir.id === currentLocation.id);
      // If we're in a directory, we don't show the directory in the breadcrumbs too.
      directory =
        directory?.parent_id === null ? undefined : structure.directories.find((d) => d.id === directory?.parent_id);
    }

    // Build breadcrumb chain from current directory up to root
    while (directory) {
      directories.push(directory);
      if (directory.parent_id === null) break;
      directory = structure.directories.find((d) => d.id === directory?.parent_id);
    }
    return directories;
  }, [currentLocation, structure]);

  return isEmpty(directoryStack) ? null : (
    <div className="flex w-full flex-col items-center gap-6">
      {directoryStack.map((directory) => (
        <BreadcrumbStackRow
          className="!px-0"
          key={directory.id}
          href={directory.name === ROOT_DIRECTORY_NAME ? `/${slug}` : getDirectoryHref({ slug, id: directory.id })}
          size={size}
          {...{
            [DATA_DIRECTORY_ID]: directory.id,
            [DATA_DIRECTORY_NAME]: directory.name,
          }}
        >
          <FileNameLabel truncationLength={256} size={16} name={directory.name} type="directory" />
        </BreadcrumbStackRow>
      ))}
    </div>
  );
};

export const BreadcrumbStackRow = ({
  size,
  breadcrumbStyle = "medium",
  children,
  disabled,
  className,
  ...props
}: BreadcrumbProps) => {
  const classes = classNames(
    "flex w-full h-28 items-center truncate rounded-ms text-text-base-3",
    disabled
      ? "cursor-not-allowed"
      : "cursor-pointer bg-background-base-3 hover:bg-background-base-325 hover:text-text-base-1 focus:bg-background-base-1 focus:shadow-focus-ring focus-visible:shadow-focus-ring focus-visible:outline-none",
    className,
  );

  return (
    <UnstyledButtonBase {...props} className={classes} title={typeof children === "string" ? children : undefined}>
      {typeof children === "string" ? <span className="truncate">{children}</span> : children}
    </UnstyledButtonBase>
  );
};
export const DATA_DIRECTORY_ID = "data-directory-id";
export const DATA_DIRECTORY_NAME = "data-directory-name";
