import { useActiveOrganization } from "@/context/ActiveOrganizationProvider";
import { getOrganizationSettingsHref } from "@/lib/path-utils";
import { classNames } from "@/lib/utils";
import { capitalizedFileType } from "@/services/files.service";
import { ModelEndpoint } from "@/services/playground.service";
import { Environment } from "@/types/app/environment";
import { Prompt } from "@/types/app/prompt";
import { DialogSection, RadixAppDialog } from "@components/library/AppDialog";
import IconButton from "@components/library/IconButton";
import { ArrowUpRightIcon, CurlIcon, JavaScriptIcon, PythonIcon, TypeScriptIcon } from "@components/library/Icons";
import { InlineLink } from "@components/library/InlineLink";
import { Scrollable } from "@components/library/Scrollable";
import { hlToast, ToastVariant } from "@components/library/Toast";
import Tooltip from "@components/library/Tooltip";
import { extractInputsFromPromptVersion } from "@components/playground/utils";
import { Tab } from "@headlessui/react";
import { Copy } from "@phosphor-icons/react";
import { API_URL } from "lib/constants";
import CopyToClipboard from "react-copy-to-clipboard";
import { SyntaxHighlighterProps } from "react-syntax-highlighter";
import CodePreview from "../Code/CodePreview";

// Base URL for the API. Is null if using the prod API URL (which should be default on SDKs).
export const API_URL_OVERRIDE: string | null =
  process.env.NEXT_PUBLIC_API_URI === API_URL ? null : process.env.NEXT_PUBLIC_API_URI || null;

/** Show the code snippets for calling a Prompt */
export const CodeSnippetDialog = ({
  prompt,
  open,
  onClose,
  environment,
}: {
  prompt: Prompt;
  open: boolean;
  onClose: () => void;
  environment?: Environment;
}) => {
  const { slug } = useActiveOrganization();
  return (
    <RadixAppDialog
      open={open}
      onClose={onClose}
      title={`${capitalizedFileType(prompt.type)} API`}
      description={
        <div className="flex flex-col gap-12">
          <p>Use the following request to generate on this {capitalizedFileType(prompt.type)}.</p>
          <p>
            Get a Humanloop API key from{" "}
            <InlineLink
              IconRight={ArrowUpRightIcon}
              target="_blank"
              href={getOrganizationSettingsHref({ slug, page: "api-keys" })}
            >
              your account page
            </InlineLink>
          </p>
          {environment?.tag === "default" && (
            <p>
              This will target the default environment:{" "}
              <span className="font-bold text-text-base-2">{environment?.name}</span>.
            </p>
          )}
          {environment?.tag === "other" && (
            <p>
              This will target the environment: <span className="font-bold text-text-base-2">{environment?.name}</span>.
            </p>
          )}
        </div>
      }
      // Stop the height shiftin on tabs
      className="!min-h-[718px] !w-[528px]"
    >
      <DialogSection>
        <CodeSnippet prompt={prompt} environment={environment} />
      </DialogSection>
    </RadixAppDialog>
  );
};

type CodeSnippetLanguage = "python" | "typescript" | "javascript" | "curl";

const CODE_SNIPPET_LANGUAGES: {
  language: CodeSnippetLanguage;
  name: string;
  Icon: (props: React.ComponentProps<"svg">) => JSX.Element;
}[] = [
  { language: "python", name: "Python", Icon: PythonIcon },
  { language: "typescript", name: "TypeScript", Icon: TypeScriptIcon },
  { language: "javascript", name: "Fetch", Icon: JavaScriptIcon },
  { language: "curl", name: "cURL", Icon: CurlIcon },
];

export const CodeSnippet = ({ prompt, environment }: { prompt: Prompt; environment?: Environment }) => {
  return (
    <Tab.Group>
      {/* TODO: Consider using @library/Tabs */}
      <Tab.List className="mb-12 flex gap-20 border-b border-oldgray-400 text-12-14">
        {CODE_SNIPPET_LANGUAGES.map(({ language, name, Icon }, i) => (
          <Tab
            key={i}
            className="-mb-1 flex items-center gap-8 border-b border-transparent px-2 pb-10 pt-[11px] font-bold text-oldgray-600 outline-none ui-selected:border-oldgray-700 ui-selected:text-oldgray-900"
          >
            <Icon className="-my-2 -ml-2 h-16 w-16 text-oldgray-600 ui-selected:text-oldgray-800" />
            {name}
          </Tab>
        ))}
        <div className="grow" />
      </Tab.List>
      <Tab.Panels>
        {CODE_SNIPPET_LANGUAGES.map(({ language }, i) => {
          const { installation, request } = createPromptCallSnippet(language, prompt, environment);
          return (
            <Tab.Panel key={i} tabIndex={-1}>
              <div className="flex flex-col gap-12">
                {installation && (
                  <div className="flex flex-col gap-8">
                    <header className="px-12 text-13-13 font-bold text-oldgray-800">Installation</header>
                    <CodePreviewWithCopyButton language="bash" code={installation} />
                  </div>
                )}
                <div className="flex flex-col gap-8">
                  <header className="px-12 text-13-13 font-bold text-oldgray-800">Request</header>
                  <CodePreviewWithCopyButton language={language} code={request || ""} />
                </div>
              </div>
            </Tab.Panel>
          );
        })}
      </Tab.Panels>
    </Tab.Group>
  );
};

interface CodePreviewWithCopyButtonProps extends Omit<SyntaxHighlighterProps, "children"> {
  code: string;
  /** Callback when the code is copied to the clipboard */
  onCopy?: () => void;
}

export const CodePreviewWithCopyButton = ({ language, code, ...props }: CodePreviewWithCopyButtonProps) => {
  const codeIsOneLine = code.split("\n").length === 1;
  return (
    <CodePreview
      language={"python"}
      {...props}
      PreTag={(props) => (
        <div className="relative min-h-0">
          <Scrollable orientation="both">
            <pre
              {...props}
              className={classNames(
                "relative rounded border border-stroke-base-2 !bg-background-base-2 text-12-16",
                props.className,
              )}
            >
              {props.children}
            </pre>
          </Scrollable>
          <div className={classNames("absolute right-8 flex gap-4", codeIsOneLine ? "top-[3px]" : "top-8")}>
            <Tooltip content="Copy snippet to clipboard" side="top">
              <span>
                <CopyToClipboard
                  text={code}
                  onCopy={() => {
                    props.onCopy?.();
                    hlToast({ variant: ToastVariant.Success, title: "Copied to clipboard!" });
                  }}
                >
                  <IconButton Icon={Copy} shade="gray" styling="solid" elevated />
                </CopyToClipboard>
              </span>
            </Tooltip>
          </div>
        </div>
      )}
      customStyle={{
        overflow: "auto",
      }}
    >
      {code}
    </CodePreview>
  );
};

export const prettyFormat = (obj: any, space: number = 4, additionalSpace: number = 0): string => {
  return JSON.stringify(obj, undefined, space).replaceAll("\n", "\n" + " ".repeat(space) + " ".repeat(additionalSpace));
};

// Information needed to create code snippet for /generate

// Created code snippet for /generate
interface PromptCallSnippet {
  installation?: string;
  request?: string;
}

interface CreateLanguageSnippet {
  prompt: Prompt;
  inputs: string[];
  environment?: Environment;
}

// TODO: Get snippets generated from docs with fern.
const createPromptCallSnippet = (
  language: CodeSnippetLanguage,
  prompt: Prompt,
  environment?: Environment,
): PromptCallSnippet => {
  let inputs: string[] = [];
  if (prompt.template !== null) {
    inputs = extractInputsFromPromptVersion(prompt);
  }
  const createLanguageSnippet = {
    prompt,
    inputs,
    environment,
  };
  switch (language) {
    case "python":
      return createPythonSdkSnippet(createLanguageSnippet);
    case "javascript":
      return createJavaScriptFetchSnippet(createLanguageSnippet);
    case "typescript":
      return createTypeScriptSdkSnippet(createLanguageSnippet);
    case "curl":
      return createCurlSnippet(createLanguageSnippet);
  }
};

const createPythonSdkSnippet = ({ prompt, inputs, environment }: CreateLanguageSnippet): PromptCallSnippet => {
  const inputsString = prettyFormat(Object.fromEntries(inputs.map((input) => [input, "INPUT_VALUE_HERE"])));
  const messagesString =
    prompt.endpoint === ModelEndpoint.chat ? `\n    messages=[{ "role": "user", "content": "Tell a joke" }],` : "";

  const environmentString =
    environment !== null && environment?.tag !== "default" ? `,\n    environment="${environment?.name}"` : "";
  const request = `import os
from humanloop import Humanloop

HUMANLOOP_API_KEY = os.getenv("HUMANLOOP_API_KEY")

humanloop = Humanloop(
    api_key=HUMANLOOP_API_KEY,${API_URL_OVERRIDE === null ? "" : '\n    base_url="' + API_URL_OVERRIDE + '/v5",'}
)

response = humanloop.prompts.call(
    id="${prompt.id}",
    inputs=${inputsString},${messagesString}
    provider_api_keys=${prettyFormat({ openai: "OPENAI_KEY_HERE" })}${environmentString}
)

print(response.logs[0].output)
`;

  return { installation: "pip install --upgrade humanloop", request };
};

const createCurlSnippet = ({ prompt, inputs, environment }: CreateLanguageSnippet): PromptCallSnippet => {
  const CURL_SPACE = 2;
  const inputsString = prettyFormat(Object.fromEntries(inputs.map((input) => [input, "INPUT_VALUE_HERE"])), CURL_SPACE);
  const messagesString =
    prompt.endpoint === ModelEndpoint.chat ? `\n  "messages": [{ "role": "user", "content": "Tell a joke" }],` : "";
  const environmentString =
    environment !== null && environment?.tag !== "default" ? `,\n  "environment": "${environment?.name}"` : "";
  const request = `curl ${API_URL_OVERRIDE || API_URL}/v5/prompts/call \\
  -H 'Accept: application/json' \\
  -H 'Content-Type: application/json' \\
  -H 'X-API-KEY: HUMANLOOP_API_KEY' \\
  -d '{
  "id": "${prompt.id}",${messagesString}
  "inputs": ${inputsString},
  "provider_api_keys": ${prettyFormat({ openai: "OPENAI_KEY_HERE" }, CURL_SPACE)}${environmentString}
}'`;
  return { request };
};

const createJavaScriptFetchSnippet = ({ prompt, inputs, environment }: CreateLanguageSnippet): PromptCallSnippet => {
  const JAVASCRIPT_SPACE = 2;
  const inputsString = prettyFormat(
    Object.fromEntries(inputs.map((input) => [input, "INPUT_VALUE_HERE"])),
    JAVASCRIPT_SPACE,
  );
  const messagesString =
    prompt.endpoint === ModelEndpoint.chat ? `\n    "messages": [{ "role": "user", "content": "Tell a joke" }],` : "";
  const environmentString =
    environment !== null && environment?.tag !== "default" ? `,\n    "environment": "${environment?.name}"` : "";

  const request = `const options = {
  method: 'POST',
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
    'X-API-KEY': process.env.HUMANLOOP_API_KEY
  },
  body: JSON.stringify({
    "id": "${prompt.id}",
    "inputs": ${inputsString},${messagesString}
    "provider_api_keys": ${prettyFormat({ openai: "OPENAI_KEY_HERE" }, JAVASCRIPT_SPACE, 2)}${environmentString}
  })
};

fetch('${API_URL_OVERRIDE || API_URL}/v5/prompts/call', options)
  .then(response => response.json())
  .then(response => console.log(response))
  .catch(err => console.error(err));
`;
  return { request };
};

const createTypeScriptSdkSnippet = ({ prompt, inputs, environment }: CreateLanguageSnippet): PromptCallSnippet => {
  const installation = `npm install humanloop`;
  const TYPESCRIPT_SPACE = 2;
  const inputsString = prettyFormat(
    Object.fromEntries(inputs.map((input) => [input, "INPUT_VALUE_HERE"])),
    TYPESCRIPT_SPACE,
  );
  const messagesString =
    prompt.endpoint === ModelEndpoint.chat ? `\n  messages: [{ role: "user", content: "Tell a joke" }],` : "";
  const environmentString =
    environment !== null && environment?.tag !== "default" ? `,\n  environment: "${environment?.name}"` : "";
  const request = `import { HumanloopClient } from "humanloop"

const humanloop = new HumanloopClient({${API_URL_OVERRIDE === null ? "" : '\n  environment: "' + API_URL_OVERRIDE + '/v5",'}
  apiKey: process.env.HUMANLOOP_API_KEY,
})

const response = await humanloop.prompts.call({
  id: "${prompt.id}",
  inputs: ${inputsString},${messagesString}
  providerApiKeys: ${prettyFormat({ openai: "OPENAI_KEY_HERE" }, TYPESCRIPT_SPACE)}${environmentString}
})


console.log(response.logs[0].output);
`;
  return { installation, request };
};
