/**
 * A pulsating dot that also opens up an educational tooltip on hover
 */

import { Tooltip } from "../Tooltip";

export const AttentionDot = ({ children }: { children: React.ReactNode }) => (
  <div className="relative">
    {children}
    {/* Pulsating dot */}
    <div className="absolute bottom-2 right-2 h-[5px] w-[5px] animate-pulse-lg rounded-full bg-icon-secondary-2" />
  </div>
);

export const Ping = ({ id }: { id: string }) => {
  return (
    <Tooltip content="Dismiss">
      <div
        id={id}
        className="absolute left-8 top-8 h-4 w-4 animate-ping cursor-pointer rounded-full bg-background-secondary-3 opacity-0 data-[state=active]:opacity-100"
        onClick={() => {
          // Click to dismiss the ping
          deactivatePing(id);
        }}
      />
    </Tooltip>
  );
};

export const activatePing = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    element.setAttribute("data-state", "active");
  }
};
const deactivatePing = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    element.setAttribute("data-state", "inactive");
  }
};
