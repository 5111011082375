import { useMonitoringFile } from "@/context/MonitoringFileContext";
import {
  capitalizedFileType,
  detachMonitoringEvaluators,
  fileHasMonitoringEvaluator,
  getRequestFromMonitoringEvaluator,
  MonitoringEvaluator,
  updateMonitoringEvaluators,
} from "@/services/files.service";
import { Evaluator } from "@/types/app/evaluator";
import { File } from "@/types/app/file";
import { Header } from "@components/SimpleTable/Headers";
import { InlineMenu } from "@components/SimpleTable/InlineMenu";
import { RadixAppDialog } from "@components/library/AppDialog";
import Button from "@components/library/Button";
import { ContextMenu } from "@components/library/ContextMenu";
import { EntityLabel } from "@components/library/Entities/EntityLabel";
import { FileLabelWithPopover } from "@components/library/Entities/FileLabelWithPopover";
import { hlToast, hlToastApiError, ToastVariant } from "@components/library/Toast";
import Tooltip, { ConditionalTooltip } from "@components/library/Tooltip";
import { XIcon } from "@heroicons/react/outline";
import { Gavel, Info, Plus } from "@phosphor-icons/react";
import { Switch } from "antd";
import { useEffect, useState } from "react";
import { FilePicker } from "../FilePicker/FilePicker";

interface MonitoringEvaluatorModalProps {
  file: File;
  mutate: (file?: File) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const MonitoringEvaluatorModal = ({ file, mutate, open, setOpen }: MonitoringEvaluatorModalProps) => {
  // Non-default evaluators (TODO: review whether we want to show default evaluators)
  const [monitoringEvaluators, setMonitoringEvaluators] = useState<MonitoringEvaluator[]>([]);
  const [filePickerOpen, setFilePickerOpen] = useState(false);

  useEffect(() => {
    if (file && "evaluators" in file && file.evaluators) {
      // Filter out default evaluators
      setMonitoringEvaluators(
        file.evaluators.filter((monitoringEvaluator) =>
          monitoringEvaluator.version_reference.type === "environment"
            ? monitoringEvaluator.version_reference.file.type === "evaluator"
            : true,
        ),
      );
    }
  }, [file]);

  const hasActiveEvaluatorVersions = monitoringEvaluators && monitoringEvaluators.length > 0;
  return (
    <>
      <RadixAppDialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        className="flex text-12-14"
        title={hasActiveEvaluatorVersions && "Monitoring"}
        description={
          hasActiveEvaluatorVersions &&
          `Connected Evaluators appear as a graph on the dashboard and a column in the Logs table.`
        }
      >
        {/* <pre className="whitespace-pre-wrap text-10-10">{JSON.stringify(monitoringEvaluators[1], null, 2)}</pre> */}
        {!hasActiveEvaluatorVersions && (
          <EmptyMonitoringModal file={file} selectEvaluator={() => setFilePickerOpen(true)} />
        )}
        {file && hasActiveEvaluatorVersions && (
          <MonitoringEvaluators
            monitoringEvaluators={monitoringEvaluators}
            file={file}
            handleConnectEvaluator={() => setFilePickerOpen(true)}
            onUpdate={() => mutate(file)}
          />
        )}
      </RadixAppDialog>
      <FilePicker
        title="Connect a Monitoring Evaluator"
        open={filePickerOpen}
        onClose={() => setFilePickerOpen(false)}
        fileTypeFilter="evaluator"
        pickerType="file"
        status={"committed"}
        initialDirectoryId={file?.directory_id}
        onVersionSubmitted={async ({ version }) => {
          if (version.type !== "evaluator") {
            throw new Error("Expected evaluator version");
          }
          if (!file) {
            throw new Error("No file selected");
          }
          try {
            const monitoringEvaluatorRequest = { evaluator_version_id: version.version_id };
            await updateMonitoringEvaluators(file?.id, {
              activate: [monitoringEvaluatorRequest],
            });
            await mutate();
            setFilePickerOpen(false);
            setOpen(true);
            if (fileHasMonitoringEvaluator(file, monitoringEvaluatorRequest)) {
              // Note that we intentionally still make the request above just in case the browser's state is incorrect.
              // This request would have no effect if the evaluator is already connected.
              hlToast({
                title: "Evaluator already connected",
                variant: ToastVariant.Info,
              });
            } else {
              hlToast({
                title: "Evaluator connected",
                variant: ToastVariant.Success,
              });
            }
          } catch (error) {
            hlToastApiError({ error, titleFallback: "Failed to connect Evaluator" });
            await mutate();
            setFilePickerOpen(false);
            setOpen(true);
          }
        }}
        onEnvironmentSubmitted={async ({ environment, file: evaluatorFile }) => {
          if (!file) {
            throw new Error("No file selected");
          }
          try {
            const monitoringEvaluatorRequest = { environment_id: environment.id, evaluator_id: evaluatorFile.id };
            await updateMonitoringEvaluators(file?.id, {
              activate: [monitoringEvaluatorRequest],
            });
            await mutate();
            setFilePickerOpen(false);
            setOpen(true);
            if (fileHasMonitoringEvaluator(file, monitoringEvaluatorRequest)) {
              // Note that we intentionally still make the request above just in case the browser's state is incorrect.
              // This request would have no effect if the evaluator is already connected.
              hlToast({
                title: "Evaluator already connected",
                variant: ToastVariant.Info,
              });
            } else {
              hlToast({
                title: "Evaluator connected",
                variant: ToastVariant.Success,
              });
            }
          } catch (error) {
            hlToastApiError({ error, titleFallback: "Failed to connect Evaluator" });
            await mutate();
            setFilePickerOpen(false);
            setOpen(true);
          }
        }}
        showEnvironments
        // disabledVersions
        resetOnClose
      />
    </>
  );
};

const MonitoringEvaluators = ({
  monitoringEvaluators,
  file,
  handleConnectEvaluator,
  onUpdate,
}: {
  monitoringEvaluators: MonitoringEvaluator[];
  file: File;
  handleConnectEvaluator: () => void;
  onUpdate: () => void;
}) => {
  const [checkedIds, setCheckedIds] = useState<string[]>([]);
  const [processingIds, setProcessingIds] = useState<string[]>([]);

  useEffect(() => {
    setCheckedIds(
      monitoringEvaluators
        .filter((monitoringEvaluator) => monitoringEvaluator.state === "active")
        .map((monitoringEvaluator) => monitoringEvaluator.version?.version_id)
        .filter((id) => id !== undefined) as string[],
    );
  }, [monitoringEvaluators]);

  return (
    <div className="flex flex-col gap-32">
      <div className="grid grid-cols-[minmax(auto,2fr)_minmax(auto,1fr)_32px] items-center gap-x-8 gap-y-24">
        <Header>Evaluator</Header>
        <Header>
          <Tooltip content="Run Evaluator on every new Log" side="bottom">
            <div className="flex items-center gap-4">
              Auto run <Info className="mb-2 h-12 w-12 text-icon-base-3" />
            </div>
          </Tooltip>
        </Header>
        <div /> {/* This is a placeholder for the header of the actions column */}
        {monitoringEvaluators.map((monitoringEvaluator) => {
          const version = monitoringEvaluator.version;
          const isHumanEvaluator = version?.spec.evaluator_type === "human";
          // TODO: Disable auto-run for external evaluators too. I don't know how the frontend handles external evaluators, if at all, at the moment.
          return (
            <>
              {/* We can be sure that version is not null here.
                  Version can only be null for the "environment" case (if there's no deployed version) */}
              {version ? (
                <FileLabelWithPopover
                  size={24}
                  file={version}
                  displayFile
                  environment={
                    monitoringEvaluator.version_reference.type === "environment"
                      ? monitoringEvaluator.version_reference.environment.name
                      : undefined
                  }
                />
              ) : monitoringEvaluator.version_reference.type === "environment" ? (
                <div>
                  <EntityLabel
                    size={24}
                    entity={monitoringEvaluator.version_reference.file.type}
                    environment={monitoringEvaluator.version_reference.environment.name}
                    fileName={monitoringEvaluator.version_reference.file.name}
                  />
                </div>
              ) : null}

              <div className="flex items-center gap-6">
                {version ? (
                  <ConditionalTooltip condition={isHumanEvaluator} content="Human evaluators cannot be auto-run">
                    <Switch
                      size="small"
                      disabled={isHumanEvaluator || processingIds.includes(version.version_id)}
                      loading={processingIds.includes(version.version_id)}
                      checked={isHumanEvaluator ? false : checkedIds.includes(version.version_id)}
                      onClick={async () => {
                        setProcessingIds((prev) => [...prev, version.version_id]);
                        const request = getRequestFromMonitoringEvaluator(monitoringEvaluator);

                        if (checkedIds.includes(version.version_id)) {
                          setCheckedIds((prev) =>
                            prev.includes(version.version_id)
                              ? prev.filter((id) => id !== version.version_id)
                              : [...prev, version.version_id],
                          );
                          await updateMonitoringEvaluators(file.id, {
                            deactivate: [request],
                          });
                        } else {
                          setCheckedIds((prev) =>
                            prev.includes(version.version_id)
                              ? prev.filter((id) => id !== version.version_id)
                              : [...prev, version.version_id],
                          );
                          await updateMonitoringEvaluators(file.id, {
                            activate: [request],
                          });
                        }
                        await onUpdate();
                        setProcessingIds((prev) => prev.filter((id) => id !== version.version_id));
                      }}
                    />
                  </ConditionalTooltip>
                ) : (
                  <span className="text-11-12 italic text-text-base-4">No deployed Version</span>
                )}
              </div>

              <InlineMenu>
                <ContextMenu.Item
                  key={"detach"}
                  withinDropdownMenu
                  onClick={async () => {
                    if (version) {
                      // This logic isn't quite right if deployed versions and attached versions overlap
                      setProcessingIds((prev) => [...prev, version.version_id]);
                    }
                    await detachMonitoringEvaluators(file.id, monitoringEvaluator);
                    await onUpdate();
                    if (version) {
                      setProcessingIds((prev) => prev.filter((id) => id !== version.version_id));
                    }
                  }}
                  IconLeft={XIcon}
                >
                  Detach
                </ContextMenu.Item>
              </InlineMenu>
            </>
          );
        })}
      </div>
      <div className="flex">
        <Button size={32} IconLeft={Plus} onClick={handleConnectEvaluator} elevated>
          Connect Evaluators
        </Button>
      </div>
    </div>
  );
};

const EmptyMonitoringModal = ({ file, selectEvaluator }: { file: File; selectEvaluator: () => void }) => {
  return (
    <div className="flex h-full w-full items-center justify-center px-[60px] pb-[60px] pt-[30px]">
      <div className="flex flex-col items-center gap-32">
        <div className="flex items-center justify-center rounded-lg border border-oldgray-400 p-[18px] text-oldgray-800 shadow-card-md ">
          <Gavel size={24} className="text-icon-base-1" />
        </div>
        <div className="flex flex-col items-center gap-24">
          <div className="flex flex-col gap-12">
            <div className="text-center text-16-24 font-bold text-text-base-1">Monitoring</div>
            <div className="text-center text-14-20 text-text-base-2">
              Connect Evaluators to this {file ? capitalizedFileType(file?.type) : "file"} to track how it performs over
              time and across versions.
            </div>
          </div>

          <div className="flex justify-center">
            <Button size={32} IconLeft={Plus} onClick={selectEvaluator} elevated>
              Connect Evaluators
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
